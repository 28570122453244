import React from "react";
import { Modal, Button } from "antd";
import { FileDoneOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UpgradeVerify = ({ isUpVerOpen, setIsUpVerOpen, text = "" }) => {
  const navigate = useNavigate();

  const estoreSet = useSelector((state) => state.estoreSet);

  return (
    <Modal
      title="Account Limits"
      open={isUpVerOpen}
      onCancel={() => {
        setIsUpVerOpen(false);
      }}
      footer={null}
      centered
      style={{ borderRadius: 12, overflow: "hidden" }}
    >
      {!estoreSet.upStatus || estoreSet.upStatus === "Pending" ? (
        <div style={{ display: "flex" }}>
          <div style={{ width: "30%" }}>
            <FileDoneOutlined style={{ fontSize: 120, color: "#009A57" }} />
          </div>
          <div style={{ width: "70%", color: "#009A57" }}>
            Please pay the one-time payment first before you can fully use this
            system.
            <br />
            <Button
              type="primary"
              className="login-form-button mb-3"
              style={{
                width: 200,
                borderRadius: 6,
                fontSize: 16,
                height: 40,
                marginTop: 10,
              }}
              onClick={() => setIsUpVerOpen(false)}
            >
              Pay Now Here
            </Button>
          </div>
        </div>
      ) : !estoreSet.upStatus2 || estoreSet.upStatus2 === "Pending" ? (
        <div style={{ display: "flex" }}>
          <div style={{ width: "30%" }}>
            <FileDoneOutlined style={{ fontSize: 120, color: "#009A57" }} />
          </div>
          <div style={{ width: "70%", color: "#009A57" }}>
            {text}
            <br />
            <Button
              type="primary"
              className="login-form-button mb-3"
              style={{
                width: 200,
                borderRadius: 6,
                fontSize: 16,
                height: 40,
                marginTop: 10,
              }}
              onClick={() => navigate(`/${estoreSet.slug}/admin/upgrade`)}
            >
              Learn More Here
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default UpgradeVerify;
