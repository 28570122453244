import React, { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import NoStore from "./pages/NoStore";
import Header from "./components/navigation/Header";
import LocationModal from "./components/modal/LocationModal";
import AdminRoute from "./components/routes/AdminRoute";
import UserRoute from "./components/routes/UserRoute";
import NoUserRoute from "./components/routes/NoUserRoute";
import TabBottom from "./components/navigation/TabBottom";

import { getEstore, getReseller, getEstoreCounters } from "./functions/estore";
import { getUserDetails, updateUserDetails } from "./functions/user";
import { loginUser, logoutUser } from "./reducers/userSlice";
import { estoreDet } from "./reducers/estoreSlice";
import { emptyCart } from "./reducers/cartSlice";
import { removeStoreCategory } from "./reducers/categorySlice";
import { removeStoreProducts } from "./reducers/productSlice";
import { removeStorePayment } from "./reducers/paymentSlice";
import { removeStoreOrders } from "./reducers/ordersSlice";
import { resellerDet } from "./reducers/resellerSlice";
import { removeStoreUsers } from "./reducers/usersSlice";
import { removeLocation } from "./reducers/locationSlice";

import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap-material-design/dist/css/bootstrap-material-design.min.css";

const Auth = lazy(() => import("./pages/auth/Auth"));
const Auth2 = lazy(() => import("./pages/auth/Auth2"));
const Home = lazy(() => import("./pages/Home"));
const Shop = lazy(() => import("./pages/Shop"));
const Cart = lazy(() => import("./pages/Cart"));
const Stores = lazy(() => import("./pages/Stores"));
const Contact = lazy(() => import("./pages/Contact"));
const About = lazy(() => import("./pages/About"));
const Checkout = lazy(() => import("./pages/Checkout"));
const PointOfSale = lazy(() => import("./pages/PointOfSale"));
const Product = lazy(() => import("./pages/Product"));
const Dashboard = lazy(() => import("./pages/admin/dashboard/Dashboard"));
const Orders = lazy(() => import("./pages/admin/order/Orders"));
const AdminOrderDetails = lazy(() =>
  import("./pages/admin/order/OrderDetails")
);
const CategoryCreate = lazy(() =>
  import("./pages/admin/category/CategoryCreate")
);
const CategoryUpdate = lazy(() =>
  import("./pages/admin/category/CategoryUpdate")
);
const CreateProduct = lazy(() => import("./pages/admin/product/CreateProduct"));
const UpdateProduct = lazy(() => import("./pages/admin/product/UpdateProduct"));
const AllProducts = lazy(() => import("./pages/admin/product/AllProducts"));
const ShowBarcodes = lazy(() => import("./pages/admin/product/ShowBarcodes"));
const PaymentCreate = lazy(() => import("./pages/admin/payment/PaymentCreate"));
const PaymentUpdate = lazy(() => import("./pages/admin/payment/PaymentUpdate"));
const Raffles = lazy(() => import("./pages/admin/raffle/Raffles"));
const ManageUser = lazy(() => import("./pages/admin/user/ManageUser"));
const Sales = lazy(() => import("./pages/admin/sales/Sales"));
const AdminSetting = lazy(() => import("./pages/admin/setting/AdminSetting"));
const Guide = lazy(() => import("./pages/admin/guide"));
const Affiliate = lazy(() => import("./pages/admin/affiliate/Affiliate"));
const Upgrade = lazy(() => import("./pages/admin/upgrade/Upgrades"));
const PayUpgrade = lazy(() => import("./pages/admin/upgrade/PayUpgrade"));
const Reseller = lazy(() => import("./pages/admin/reseller/Reseller"));
const Inventory = lazy(() => import("./pages/admin/inventory/Inventory"));
const Location = lazy(() => import("./pages/admin/location/Location"));
const LocationUpdate = lazy(() =>
  import("./pages/admin/location/LocationUpdate")
);
const BrandCreate = lazy(() => import("./pages/admin/brand/BrandCreate"));
const BrandUpdate = lazy(() => import("./pages/admin/brand/BrandUpdate"));

const UserOrder = lazy(() => import("./pages/user/UserOrder"));
const UserAccount = lazy(() => import("./pages/user/UserAccount"));
const UserDelete = lazy(() => import("./pages/user/UserDelete"));
const OrderDetails = lazy(() => import("./pages/user/OrderDetails"));
const UserRaffles = lazy(() => import("./pages/user/UserRaffles"));

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const estoreLocal = localStorage.getItem("estore")
    ? JSON.parse(localStorage.getItem("estore"))
    : {};
  const resellerLocal = localStorage.getItem("reseller")
    ? JSON.parse(localStorage.getItem("reseller"))
    : {};

  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);

  const [slug, setSlug] = useState("");
  const [estore, setEstore] = useState({});
  const [locModalVisible, setLocModalVisible] = useState(false);
  const [locChange, setLocChange] = useState(false);

  useEffect(() => {
    document.title = resellerLocal && resellerLocal.appName;

    if (slug) {
      if (slug !== estoreLocal.slug) loadEstore(slug);
    } else if (estoreLocal && estoreLocal._id) {
      setEstore(estoreLocal);
      if (estoreLocal && estoreLocal._id) {
        checkEstoreCounters();
      }
    } else if (process.env.REACT_APP_ESTORE_DEFAULT_SLUG !== "gratis") {
      loadEstore(process.env.REACT_APP_ESTORE_DEFAULT_SLUG);
    }

    if (resellerLocal && resellerLocal.appName) {
      dispatch(resellerDet(resellerLocal));
      checkResellerCounters();
    } else {
      loadReseller();
    }

    if (
      estoreLocal &&
      estoreLocal._id &&
      (!estoreLocal.upStatus || estoreLocal.upStatus === "Pending")
    ) {
      navigate(`/${estoreLocal.slug}/admin/payupgrade/1`);
    }
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadEstore = (slug) => {
    getEstore(slug, process.env.REACT_APP_ESTORE_DEFAULT_ID).then((res) => {
      if (res.data && res.data.err) {
        toast.error(res.data.err);
      } else {
        if (res.data) {
          setEstore(res.data);
          dispatch(estoreDet(res.data));
          localStorage.setItem("estore", JSON.stringify(res.data));
        }
      }
    });
  };

  const checkEstoreCounters = () => {
    getEstoreCounters(estoreLocal._id).then((res) => {
      if (res.data && res.data.err) {
        toast.error(res.data.err);
      } else {
        let estoreObj = {};
        if (estoreLocal.orderChange !== res.data.orderChange) {
          dispatch(removeStoreOrders([]));
          localStorage.removeItem("orders");
          localStorage.removeItem("sales");
          estoreObj = { ...estoreObj, orderChange: res.data.orderChange };
        }
        if (estoreLocal.categoryChange !== res.data.categoryChange) {
          dispatch(removeStoreCategory([]));
          localStorage.removeItem("categories");
          estoreObj = { ...estoreObj, categoryChange: res.data.categoryChange };
        }
        if (estoreLocal.productChange !== res.data.productChange) {
          dispatch(removeStoreProducts([]));
          localStorage.removeItem("products");
          localStorage.removeItem("inventory");
          estoreObj = { ...estoreObj, productChange: res.data.productChange };
        }
        if (estoreLocal.paymentChange !== res.data.paymentChange) {
          dispatch(removeStorePayment([]));
          localStorage.removeItem("payments");
          estoreObj = { ...estoreObj, paymentChange: res.data.paymentChange };
        }
        if (estoreLocal.userChange !== res.data.userChange) {
          dispatch(removeStoreUsers([]));
          localStorage.removeItem("users");
          estoreObj = { ...estoreObj, userChange: res.data.userChange };
        }
        if (estoreLocal.locationChange !== res.data.locationChange) {
          setLocChange(true);
        }
        if (estoreLocal.estoreChange !== res.data.estoreChange) {
          if (estoreLocal.slug) {
            loadEstore(estoreLocal.slug);
          }
        } else {
          setEstore({ ...estoreLocal, ...estoreObj });
          dispatch(estoreDet({ ...estoreLocal, ...estoreObj }));
          localStorage.setItem(
            "estore",
            JSON.stringify({ ...estoreLocal, ...estoreObj })
          );
        }
      }
    });
  };

  const loadReseller = () => {
    getReseller(process.env.REACT_APP_ESTORE_DEFAULT_ID).then((res) => {
      if (res.data && res.data.err) {
        toast.error(res.data.err);
      } else {
        if (res.data) {
          document.title = res.data.reseller && res.data.reseller.appName;
          dispatch(
            resellerDet({
              ...res.data.reseller,
              currency: res.data.currency,
              estoreChange: res.data.estoreChange,
              resellid: res.data.resellid,
              payments: res.data.payments,
            })
          );
          localStorage.setItem(
            "reseller",
            JSON.stringify({
              ...res.data.reseller,
              currency: res.data.currency,
              estoreChange: res.data.estoreChange,
              resellid: res.data.resellid,
              payments: res.data.payments,
            })
          );
        }
      }
    });
  };

  const checkResellerCounters = () => {
    getEstoreCounters(process.env.REACT_APP_ESTORE_DEFAULT_ID).then((res) => {
      if (res.data && res.data.err) {
        toast.error(res.data.err);
      } else {
        if (resellerLocal.estoreChange !== res.data.estoreChange) {
          loadReseller();
        }
      }
    });
  };

  useEffect(() => {
    if (userToken && userToken !== "undefined") {
      handleUserDetails(userToken);
    } else {
      if (
        slug &&
        estoreLocal &&
        estoreLocal.locationType &&
        estoreLocal.locationType === "specific"
      ) {
        const address = JSON.parse(sessionStorage.getItem("address"));
        if (address && address.addiv3 && address.addiv3._id) {
          dispatch(
            loginUser({
              address,
            })
          );
        } else {
          if (!sessionStorage.getItem("popUpLocation")) {
            setLocModalVisible(true);
          }
        }
      }
    }
  }, [user.role, slug, estore]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUserDetails = (token) => {
    getUserDetails(
      estoreLocal._id,
      process.env.REACT_APP_ESTORE_DEFAULT_ID,
      token
    ).then((res) => {
      if (res.data.err) {
        toast.error(res.data.err);
      } else {
        if (
          res.data &&
          res.data.estoreid &&
          res.data.estoreid._id === estoreLocal._id
        ) {
          if (locChange) {
            updateUserDetails(
              res.data.estoreid._id,
              { ...res.data, address: {} },
              token
            ).then((res) => {
              if (res.data.err) {
                toast.error(res.data.err);
              } else {
                setLocChange(false);
                dispatch(
                  loginUser({
                    address: {},
                  })
                );
                dispatch(removeLocation({}));
                localStorage.removeItem("location");
              }
            });
          }
          assignUserDetails(res.data, token);
        } else {
          if (
            res.data &&
            res.data.estoreid &&
            res.data.estoreid.upgradeType === "2" &&
            res.data.estoreid.upStatus2 === "Active"
          ) {
            if (estoreLocal && estoreLocal._id) {
              updateUserDetails(
                res.data.estoreid._id,
                { ...res.data, estoreid: estoreLocal._id },
                token
              ).then((res) => {
                if (res.data.err) {
                  toast.error(res.data.err);
                }
              });
            } else {
              window.location.reload();
            }
          } else {
            localStorage.clear();
            dispatch(logoutUser());
            dispatch(emptyCart());
            localStorage.removeItem("cart");
          }
        }
      }
    });
  };

  const assignUserDetails = (user, token) => {
    dispatch(
      loginUser({
        ...user,
        token,
      })
    );
    if (
      user &&
      user.address &&
      user.address.addiv3 &&
      user.address.addiv3._id
    ) {
      setLocModalVisible(false);
    } else {
      if (
        slug &&
        estoreLocal &&
        estoreLocal.locationType &&
        estoreLocal.locationType === "specific" &&
        !sessionStorage.getItem("popUpLocation")
      ) {
        setLocModalVisible(true);
      }
    }
  };

  return (
    <>
      <div id="non-printable" style={{ height: 56 }}>
        <Header setLocModalVisible={setLocModalVisible} />
        <LocationModal
          locModalVisible={locModalVisible}
          setLocModalVisible={setLocModalVisible}
        />
      </div>
      <Suspense>
        <Routes>
          <Route
            exact
            path="/"
            element={
              estore &&
              estore.upgradeType === "2" &&
              estore.upStatus2 === "Active" ? (
                <Auth2 />
              ) : user && user._id ? (
                <NoStore />
              ) : (
                <Auth />
              )
            }
          />
          <Route exact path="/stores" element={<Stores />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/about" element={<About />} />
          <Route
            exact
            path="/:slug"
            element={
              <NoUserRoute setSlug={setSlug} estore={estore}>
                <Home />
              </NoUserRoute>
            }
          />
          <Route
            exact
            path="/:slug/shop"
            element={
              <NoUserRoute setSlug={setSlug} estore={estore}>
                <Shop />
              </NoUserRoute>
            }
          />
          <Route
            exact
            path="/:slug/shop/:catSlug"
            element={
              <NoUserRoute setSlug={setSlug} estore={estore}>
                <Shop />
              </NoUserRoute>
            }
          />
          <Route
            exact
            path="/:slug/cart"
            element={
              <NoUserRoute setSlug={setSlug} estore={estore}>
                <Cart setLocModalVisible={setLocModalVisible} />
              </NoUserRoute>
            }
          />
          <Route
            exact
            path="/:slug/product/:prodslug"
            element={
              <NoUserRoute setSlug={setSlug} estore={estore}>
                <Product />
              </NoUserRoute>
            }
          />
          <Route
            exact
            path="/:slug/auth"
            element={<Auth setSlug={setSlug} from="header" />}
          />
          <Route
            exact
            path="/:slug/checkout"
            element={
              <UserRoute>
                {cart && cart.length > 0 ? (
                  <Checkout setSlug={setSlug} estore={estore} />
                ) : (
                  <Home setSlug={setSlug} estore={estore} />
                )}
              </UserRoute>
            }
          />
          <Route
            exact
            path="/:slug/pos"
            element={
              <UserRoute>
                {cart && cart.length > 0 ? (
                  <PointOfSale setSlug={setSlug} estore={estore} />
                ) : (
                  <Home setSlug={setSlug} estore={estore} />
                )}
              </UserRoute>
            }
          />
          <Route
            exact
            path="/:slug/user/order"
            element={
              <UserRoute>
                <UserOrder setSlug={setSlug} estore={estore} />
              </UserRoute>
            }
          />
          <Route
            exact
            path="/:slug/user/order/:orderid"
            element={
              <UserRoute>
                <OrderDetails setSlug={setSlug} estore={estore} />
              </UserRoute>
            }
          />
          <Route
            exact
            path="/:slug/user/raffle"
            element={
              <UserRoute>
                <UserRaffles setSlug={setSlug} estore={estore} />
              </UserRoute>
            }
          />
          <Route
            exact
            path="/:slug/user/account"
            element={
              <UserRoute>
                <UserAccount setSlug={setSlug} estore={estore} />
              </UserRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/dashboard"
            element={
              <AdminRoute>
                <Dashboard setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/orders"
            element={
              <AdminRoute>
                <Orders setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/order/:orderid"
            element={
              <AdminRoute>
                <AdminOrderDetails setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/category"
            element={
              <AdminRoute>
                <CategoryCreate setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/category/:catid"
            element={
              <AdminRoute>
                <CategoryUpdate setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/brand"
            element={
              <AdminRoute>
                <BrandCreate setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/brand/:braid"
            element={
              <AdminRoute>
                <BrandUpdate setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/product"
            element={
              <AdminRoute>
                <CreateProduct setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/product/:prodid"
            element={
              <AdminRoute>
                <UpdateProduct setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/products"
            element={
              <AdminRoute>
                <AllProducts setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/barcodes"
            element={
              <AdminRoute>
                <ShowBarcodes setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/payment"
            element={
              <AdminRoute>
                <PaymentCreate setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/payment/:payid"
            element={
              <AdminRoute>
                <PaymentUpdate setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/raffle"
            element={
              <AdminRoute>
                <Raffles setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/manageuser"
            element={
              <AdminRoute>
                <ManageUser setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/sales"
            element={
              <AdminRoute>
                <Sales setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/inventory"
            element={
              <AdminRoute>
                <Inventory setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/location"
            element={
              <AdminRoute>
                <Location setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/location/:addiv3"
            element={
              <AdminRoute>
                <LocationUpdate setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/setting"
            element={
              <AdminRoute>
                <AdminSetting setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/upgrade"
            element={
              <AdminRoute>
                <Upgrade setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/affiliate"
            element={
              <AdminRoute>
                <Affiliate setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/reseller"
            element={
              <AdminRoute>
                <Reseller setSlug={setSlug} estore={estore} />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/:slug/admin/payupgrade/:type"
            element={<PayUpgrade setSlug={setSlug} estore={estore} />}
          />
          <Route
            exact
            path="/:slug/admin/guide"
            element={<Guide setSlug={setSlug} estore={estore} />}
          />
          <Route
            exact
            path="/:slug/admin/guide/:videoid"
            element={<Guide setSlug={setSlug} estore={estore} />}
          />
          <Route
            exact
            path="/:slug/user/delete"
            element={<UserDelete setSlug={setSlug} estore={estore} />}
          />
        </Routes>
      </Suspense>
      <TabBottom />
      <ToastContainer />
    </>
  );
};

export default App;
