import React from "react";
import { useSelector } from "react-redux";
import LoadingToRedirect from "./LoadingToRedirect";

const AdminRoute = ({ children }) => {
  const user = useSelector((state) => state.user);

  return user && ["admin", "moderator", "cashier"].includes(user.role) ? (
    <>{children}</>
  ) : (
    <LoadingToRedirect />
  );
};

export default AdminRoute;
