const getUnique = (origData, newData) => {
  const result =
    origData &&
    origData.filter((oldD) => {
      const existData = newData.filter(
        (newD) =>
          newD._id === oldD._id ||
          (newD.slug && oldD.slug === newD.slug && oldD.slug)
      );
      return !existData.length;
    });

  const newDataWithPages =
    newData &&
    newData.map((newD) => {
      const checkPage = origData.find(
        (oldD) =>
          newD._id === oldD._id ||
          (newD.slug && oldD.slug === newD.slug && oldD.slug)
      );
      if (checkPage && checkPage._id) {
        if (
          checkPage.pages &&
          checkPage.pages.length > 0 &&
          newD.pages &&
          newD.pages.length > 0
        ) {
          return { ...newD, pages: [...checkPage.pages, ...newD.pages] };
        } else if (checkPage.pages && checkPage.pages.length > 0) {
          return { ...newD, pages: checkPage.pages };
        } else {
          return newD;
        }
      } else {
        return newD;
      }
    });

  return [...result, ...newDataWithPages];
};

export default getUnique;
